
import Home from "../views/home/index.vue";

export const routes = [
    {
        path: "*",
        component: () => import('../views/error/err404.vue'),
        hidden: true
    },
    {
        path: "/401",
        component: () => import('../views/error/err401.vue'),
        name: "401",
        hidden: true
    },
    {
        path: "/500",
        component: () => import('../views/error/err500.vue'),
        name: "500",
        hidden: true
    },
    {
        path: "/",
        component: Home,
        redirect: "/readme/main",
        name: "首页",
        hidden: true
    },
    {
        path: "/readme",
        component: Home,
        icon: "icon-kongzhitai",
        name: "控制台",
        noDropdown: true,
        children: [
            {
                path: "main",
                component: () => import('../views/home/main')
            }
        ]
    },
    {
        path: "/login",
        component: () => import('../views/login.vue'),
        meta: {
            title: ''
        }
    },
]

export const authRoutes = [
    {
        path: "/admin",
        component: Home,
        icon: "icon-gonggongquanxian",
        name: "权限管理",
        meta: {
            authRule: ["/auth"]
        },
        children: [
            {
                path: "index",
                component: () => import('../views/admin/authAdmin.vue'),
                name: "管理员管理",
                icon: "",
                meta: {
                    authRule: ["/admin/list"]
                },
            },
            {
                path: "rule",
                component: () => import('../views/admin/adminPermissionRule.vue'),
                name: "权限规则",
                icon: "",
                meta: {
                    authRule: ["/admin/permissionRule/list"]
                },
            },
            // {
            //     path: "log",
            //     component: () => import('../views/admin/log.vue'),
            //     name: "操作日志",
            //     icon: "",
            //     meta: {
            //         authRule: ["/admin/adminLog/list"]
            //     },
            // }
        ]
    },
    {
        path: "/system",
        component: Home,
        icon: "icon-xitongguanli",
        name: "系统管理",
        meta: {
            authRule: ["/system"]
        },
        children: [
            {
                path: "setting",
                component: () => import('../views/system/setting.vue'),
                name: "系统配置",
                icon: "",
                meta: {
                    authRule: ["/admin/setting/list"]
                },
            },
            // {
            //     path: "notice",
            //     component: () => import('../views/system/notice.vue'),
            //     name: "定时任务",
            //     icon: "",
            //     meta: {
            //         authRule: ["/admin/notice/list"]
            //     },
            // },
        ]
    },
    {
        path: "/group",
        component: Home,
        icon: "icon-chanpin",
        name: "信息管理",
        meta: {
            authRule: ["/info"]
        },
        children: [
            {
                path: "chat",
                component: () => import('../views/group/chat.vue'),
                name: "采集聊天",
                icon: "",
                meta: {
                    authRule: ["/admin/chat/list"]
                },
            },
            {
                path: "dataCollection",
                component: () => import('../views/group/dataCollection.vue'),
                name: "采集数据",
                icon: "",
                meta: {
                    authRule: ["/admin/dataCollection/list"]
                },
            },
            {
                path: "bot",
                component: () => import('../views/bot/bot.vue'),
                name: "导航机器人",
                icon: "",
                meta: {
                    authRule: ["/admin/bot/list"]
                },
            },
            {
                path: "group",
                component: () => import('../views/group/group.vue'),
                name: "导航群组",
                icon: "",
                meta: {
                    authRule: ["/admin/group/list"]
                },
            },
            {
                path: "ad",
                component: () => import('../views/group/ad.vue'),
                name: "搜索广告",
                icon: "",
                meta: {
                    authRule: ["/admin/ad/list"]
                },
            },
            {
                path: "history",
                component: () => import('../views/group/history.vue'),
                name: "搜索记录",
                icon: "",
                meta: {
                    authRule: ["/admin/history/list"]
                },
            },
        ]
    },
]
