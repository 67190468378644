export const BASE_RPC_URLS = {
    1: 'https://mainnet.infura.io/v3/adee4ead47844d238802431fcb7683c6',
    56: 'https://bscrpc.com',
    66: 'https://exchainrpc.okex.org',
}

export const BASE_SCAN_URLS = {
    1: 'https://etherscan.io',
    56: 'https://bscscan.com',
    66: 'https://www.oklink.com/en/okc',
}

export const API_HOST = process.env.VUE_APP_API_HOST || window.location.protocol+"//"+window.location.host+"/api"

export const STATIC_URL = ""

export const ROUTER_WHITELIST = [
    "/401", "/404", "/500","/readme/main"
]

export const LANGUAGE_LIST = [
    {
        locale: 'en-US',
        language: 'English',
    },
    // {
    //     locale: 'es-ES',
    //     language: 'Español',
    // },
    // {
    //     locale: 'ru-RU',
    //     language: 'Русский',
    // },
    // {
    //     locale: 'de-DE',
    //     language: 'Deutsch',
    // },
    // {
    //     locale: 'fr-FR',
    //     language: 'Français',
    // },
    // {
    //     locale: 'it-IT',
    //     language: 'Italiano',
    // },
    // {
    //     locale: 'pt-BR',
    //     language: 'Português (Brazil)',
    // },
    // {
    //     locale: 'id-ID',
    //     language: 'Bahasa Indonesia',
    // },
    // {
    //     locale: 'tr-TR',
    //     language: 'Türkçe',
    // },
    // {
    //     locale: 'vi-VN',
    //     language: 'Tiếng Việt',
    // },
    // {
    //     locale: 'ja-JP',
    //     language: '日本語',
    // },
    // {
    //     locale: 'ko-KR',
    //     language: '한국어',
    // },
    {
        locale: 'zh-CN',
        language: '简体',
    },
    {
        locale: 'zh-TW',
        language: '繁体',
    },
]